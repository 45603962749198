<template>
	<div class="flex flex-col items-center justify-center overflow-y-hidden no-scrollbar">
		<WemotooHeader :absolute="true" :transparent="true" class="pt-10" />

		<HeroCarousel />
		<WemotooPartnership />
		<div id="eventSection">
			<EventSection />
		</div>
		<WemotooDownloadApp />
		<WemotooFooter />
	</div>
</template>

<script lang="ts" setup>
definePageMeta({
	colorMode: 'light',
	title: 'Wemotoo - Msia Motor Club',
	description:
		'for all motor lovers, users and any person joining the club , they can find all upcoming motor events in Malaysia, updates and announcements. We provide Digital ticketing to check in the event , the sponsorship and info knowledge regarding all motors.',
});

useSeoMeta({
	title: 'Wemotoo - Msia Motor Club',
	ogTitle: 'Wemotoo - Msia Motor Club',
	description:
		'for all motor lovers, users and any person joining the club , they can find all upcoming motor events in Malaysia, updates and announcements. We provide Digital ticketing to check in the event , the sponsorship and info knowledge regarding all motors.',
	ogDescription:
		'for all motor lovers, users and any person joining the club , they can find all upcoming motor events in Malaysia, updates and announcements. We provide Digital ticketing to check in the event , the sponsorship and info knowledge regarding all motors.',
});

console.log('v1.0.5');
const firebase = useFirebaseStore();
firebase.logCustomEvent(HOME_VIEW);
</script>

<style></style>
